import i18n from "i18next";
const supportSite = "https://developer.mercedes-benz.com/contact/vehicle_images/technical_inquiry";

function _handleErrors(err, theObject){ 
    let localLanguage = i18n.language;
    let statusCode = err.response.status;
    let errorMessage ='';
    
    if(localLanguage === "de" || localLanguage === "de-AT" || localLanguage === "de-DE"  || localLanguage === "de-LI" || localLanguage === "de-CH" ){
      switch(statusCode){
        case 400 :
        errorMessage = 'Ein Fehler ist aufgetreten ['+statusCode +'] für ['+theObject +']. Die Anfrage ist ungültig. Bitte prüfen.'
        break;
        case 404 :
        errorMessage = 'Ein Fehler ist aufgetreten ['+statusCode +'] für ['+theObject +']. Das Fahrzeug wurde nicht gefunden oder die FIN ist ungültig. Bitte prüfen.'
        break;
        case 401 : 
        errorMessage = 'Ein Fehler ist aufgetreten ['+statusCode +'] für ['+theObject +']. Ihr API Key ist ungültig. Bitte prüfen.'
        break;
        case 403 : 
        errorMessage = 'Ein Fehler ist aufgetreten ['+statusCode +'] für ['+theObject +']. Ihr API Key ist ungültig. Bitte prüfen.'
        break;
        default: 
        errorMessage = 'Ein Fehler ist aufgetreten ['+statusCode +'] für ['+theObject +']. Bitte prüfen. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Support unter <a href="'+supportSite+'" target="_blank">Mercedes-Benz Developer Support</a>.'
      }
    }else{
      switch(statusCode){
        case 400 :
        errorMessage = 'An error occurred ['+statusCode +'] for ['+theObject +']. Your request is invalid. Please check.'
        case 404 : 
        errorMessage = 'An error occurred ['+statusCode +'] for ['+theObject +']. Your VIN key is invalid. Please check.'
        break;
        case 401 : 
        errorMessage = 'An error occurred ['+statusCode +'] for ['+theObject +']. Your API key is invalid. Please check.'
        break;
        case 403 : 
        errorMessage = 'An error occurred ['+statusCode +'] for ['+theObject +']. Your API key is invalid. Please check.'
        break;
        default: 
        errorMessage = 'An error occurred ['+statusCode +'] for ['+theObject +']. Please check. If the problem persists, please contact support at <a href="'+supportSite+'" target="_blank">Mercedes-Benz Developer Support</a>.'
      }
    }
    return errorMessage;
}

export default _handleErrors;